import React, { Component } from 'react';

import PLUPlannerStore from 'stores/Settings/PLUPlannerStore';

import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import style from './PLUPlanner.module.scss';

interface Props {
  store: PLUPlannerStore;
}

class PLUPlannerCalendar extends Component<Props> {
  render() {
    const { store } = this.props;

    return (
      <div className={style.calendar}>
        <Button styleType="text" onClick={store.toPrevWeek}>
          <Icon type="arrowLeft" />
        </Button>

        <Datepicker
          value={[store.weekStartDate, store.weekEndDate]}
          onChange={store.changeDate}
          selectRange={false}
          showPeriods={false}
          showWeekNumbers
        />

        <Button styleType="text" onClick={store.toNextWeek}>
          <Icon type="arrowRight" />
        </Button>
      </div>
    );
  }
}

export default PLUPlannerCalendar;
