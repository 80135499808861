import React, { Component } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import { PLUItem } from 'http/Api/PLUItems/types';
import { replaceUrlParams } from 'http/helpers';
import { ROUTES } from 'routes/routes';
import AppRouter from 'stores/AppRouter';
import PLUPlannerStore from 'stores/Settings/PLUPlannerStore';
import ModalStore from 'stores/ModalStore';

import WarningModal from 'components/Modals/Warning/WarningModal';
import TooltipComponent from 'components/Tooltip/Tooltip';
import PLUPlannerItemOverlay from './PLUPlannerItemOverlay';
import style from './PLUPlanner.module.scss';

interface Props {
  date: string;
  disabled?: boolean;
  items: PLUItem[];
  store: PLUPlannerStore;
}

@observer
class PLUPlannerWeekday extends Component<Props> {
  render() {
    const { disabled, items, store } = this.props;

    return (
      <div
        className={classNames(
          style.td,
          style.day,
          disabled && style.day__disabled,
        )}
      >
        <div className={style.line__wrapper}>
          <div className={style.line__day}>
            {items.map(item => (
              <TooltipComponent
                key={item.pluId}
                className={style.tooltip__wrapper}
                content={
                  <PLUPlannerItemOverlay
                    title={store.getItemTitle(item)}
                    onEditClick={() => this._handleEditButtonClick(item.pluId)}
                    onDeleteClick={() =>
                      this._handleDeleteButtonClick(item.pluId)
                    }
                  />
                }
              >
                <div
                  className={style.pluItem}
                  style={this._calculateItemPosition(item)}
                />
              </TooltipComponent>
            ))}
          </div>
        </div>
      </div>
    );
  }

  private _handleEditButtonClick = (id: string) => {
    AppRouter.push(this._getLink(id));
  };

  private _handleDeleteButtonClick = async (id: string) => {
    await ModalStore.showModal(WarningModal, {
      title: 'modal.warning.title.delete',
      description: 'modal.warning.delete.item',
    });

    await this.props.store.handleDelete(id);
  };

  private _getLink = (id: string) => {
    return replaceUrlParams(ROUTES.admin.settingsPluItem, { id });
  };

  private _calculateItemPosition = (item: PLUItem) => {
    const [fromHours, fromMinutes] = item.from.split(':');
    const [toHours, toMinutes] = item.to.split(':');

    const diffInMinutes =
      (+toHours - +fromHours) * 60 + (+toMinutes - +fromMinutes);
    const fromInMinutes = +fromHours * 60 + +fromMinutes;
    const minutesInDay = 1440;

    const height = ((diffInMinutes / minutesInDay) * 100).toFixed(0) + '%';
    const top = ((fromInMinutes / minutesInDay) * 100).toFixed(0) + '%';

    return { height, top };
  };
}

export default PLUPlannerWeekday;
