import React, { Component } from 'react';

import { MultiSelectField } from 'components/MultiSelect/MultiSelectField';
import { MultiSelectOptions } from 'components/MultiSelect/MultiSelectOptions';
import { MultiSelectContainer } from 'components/MultiSelect/MultiSelectContainer';

export enum DayOptionValue {
  Monday = '1',
  Tuesday = '2',
  Wednesday = '3',
  Thursday = '4',
  Friday = '5',
  Saturday = '6',
  Sunday = '7',
}

const weekDayOptions = [
  {
    label: 'MONDAY',
    id: DayOptionValue.Monday,
    holiday: false,
  },
  {
    label: 'TUESDAY',
    id: DayOptionValue.Tuesday,
    holiday: false,
  },
  {
    label: 'WEDNESDAY',
    id: DayOptionValue.Wednesday,
    holiday: false,
  },
  {
    label: 'THURSDAY',
    id: DayOptionValue.Thursday,
    holiday: false,
  },
  {
    label: 'FRIDAY',
    id: DayOptionValue.Friday,
    holiday: false,
  },
  {
    label: 'SATURDAY',
    id: DayOptionValue.Saturday,
    holiday: true,
  },
  {
    label: 'SUNDAY',
    id: DayOptionValue.Sunday,
    holiday: true,
  },
];

interface Props {
  value: string[];
  fieldClassName?: string;
  disabled?: boolean;
  onChange?: (value: string[]) => void;
}

class DaysMultiSelect extends Component<Props> {
  render() {
    const { value, fieldClassName, disabled } = this.props;

    return (
      <MultiSelectContainer
        disabled={disabled}
        context={{
          options: weekDayOptions,
          additionalOptions: this._additionalOptions,
          selectedOptionIds: value,
          toggleOption: this._handleOptionToggle,
        }}
      >
        <MultiSelectField className={fieldClassName} />
        <MultiSelectOptions />
      </MultiSelectContainer>
    );
  }

  private _handleOptionToggle = (optionId: string) => {
    const { value, onChange } = this.props;

    if (value.includes(optionId)) {
      const newValue = value.filter(id => id !== optionId);

      onChange?.(newValue);
      return;
    }

    onChange?.([...value, optionId]);
  };

  private _handleEveryDayClick = () => {
    this.props.onChange?.([]);
  };

  private _handleWeekDaysClick = () => {
    this.props.onChange?.([
      DayOptionValue.Monday,
      DayOptionValue.Tuesday,
      DayOptionValue.Wednesday,
      DayOptionValue.Thursday,
      DayOptionValue.Friday,
    ]);
  };

  private get _additionalOptions() {
    return [
      {
        id: 'everyDay',
        label: 'select.options.everyday',
        onClick: this._handleEveryDayClick,
      },
      {
        id: 'weekdays',
        label: 'select.options.weekdays',
        onClick: this._handleWeekDaysClick,
      },
    ];
  }
}

export default DaysMultiSelect;
