export enum PLUItemDateType {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export enum PLUItemMenuItemType {
  Standard = 'standard',
  Temporary = 'temporary',
}
