import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import TooltipComponent from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';

import style from './FormStepper.module.scss';

interface Props {
  label: string;
  tooltip?: string;
}

class FormStepperTitle extends Component<Props> {
  render() {
    const { label, tooltip } = this.props;

    return (
      <div className={style.step__title}>
        <FormattedMessage id={label} />

        {tooltip && (
          <TooltipComponent content={<FormattedMessage id={tooltip} />}>
            <Icon className={style.step__icon} type={'helpCircle'} />
          </TooltipComponent>
        )}
      </div>
    );
  }
}

export default FormStepperTitle;
