import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import AppRouter from 'stores/AppRouter';
import { ROUTES } from 'routes/routes';

import TabsControlled, {
  AppTabControlled,
} from 'components/Tabs/TabsControlled';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import style from './PLUSetting.module.scss';
import PLUPlanner from './Planner/PLUPlanner';
import PLUPlannerStore, {
  PLUPlannerTab,
} from 'stores/Settings/PLUPlannerStore';

export enum PLUTabs {
  Spt,
  Consumer,
}

@observer
class PLUSetting extends Component {
  private _pluPlannerStore = new PLUPlannerStore();

  componentDidMount() {
    this._pluPlannerStore.init();
  }

  private _tabs: AppTabControlled[] = [
    {
      title: 'title.settings.plu.tabs.spt',
      index: PLUPlannerTab.Spt,
    },
    {
      title: 'title.settings.plu.tabs.consumer',
      index: PLUPlannerTab.Consumer,
    },
  ];

  private _handleAddButtonClick() {
    AppRouter.push(ROUTES.admin.settingsPluItemNew);
  }

  render() {
    return (
      <>
        <TabsControlled
          tabs={this._tabs}
          activeTab={this._pluPlannerStore.tab}
          onTabClick={this._pluPlannerStore.handleTabChange}
        />

        <div className={style.actions}>
          <Button className={style.btn} onClick={this._handleAddButtonClick}>
            <Icon type="cross" className={style.icon} />
            <FormattedMessage id="title.settings.plu.button.add" />
          </Button>
        </div>

        <PLUPlanner store={this._pluPlannerStore} />
      </>
    );
  }
}

export default PLUSetting;
