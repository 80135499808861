import { action, computed, observable } from 'mobx';
import {
  EditModeActions,
  IGroupTag,
} from 'components/Modals/TagsSelectModal/TagsSelectModal';
import { sortByAccessor } from 'helpers/accessors';
import { SortConfig } from 'helpers/types';

class TagsSelectModalStore<T extends IGroupTag> {
  @observable tags: T[] = [];
  @observable selectedTags: T[] = [];
  @observable searchValue: string = '';
  @observable loading = false;

  editModeActions: EditModeActions<T> = {
    onDeleteTag: async () => undefined,
    onCreateTag: async () => undefined,
  };
  withGroups: boolean;

  private _isGroupTagUnselectable = false;
  private _defaultSortByTitle: SortConfig = {
    accessor: 'title',
    desc: false,
  };

  constructor(
    tags: T[],
    selectedTags: T[],
    editingMode?: EditModeActions<T>,
    withGroups?: boolean,
    isGroupTagUnselectable?: boolean,
  ) {
    this.tags = tags;
    this.selectedTags = selectedTags;
    this.withGroups = !!withGroups;
    this._isGroupTagUnselectable = !!isGroupTagUnselectable;

    if (editingMode) {
      this.editModeActions = editingMode;
    }
  }

  @computed
  get tagsIds() {
    return this.selectedTags.map(tag => tag.id);
  }

  @computed
  get filteredTags() {
    const regExp = new RegExp(this.searchValue, 'i');

    const tagsList = this.searchValue
      ? this.tags.filter(
          item =>
            regExp.test(item.title) &&
            !(this._isGroupTagUnselectable && item.isGroup),
        )
      : this.tags;

    return this.withGroups
      ? [...tagsList]
      : [...tagsList].sort(sortByAccessor(this._defaultSortByTitle));
  }

  @computed
  get isSelectAllTags(): boolean {
    return this.selectedTags.length === this.tags.length;
  }

  @action
  onSearch = value => {
    this.searchValue = value;
  };

  @action
  onSelectAllClick = () => {
    this.selectedTags = this.isSelectAllTags ? [] : [...this.tags];
  };

  @action
  onTagClick = (tag: any) => () => {
    const isSelected = this.tagsIds.includes(tag.id);
    if (this.withGroups) {
      if (tag.isGroup) {
        const groupItems =
          this.tags.filter(item => (item as IGroupTag).groupId === tag.id) ||
          [];
        this.selectedTags = isSelected
          ? this.selectedTags.filter(
              item =>
                item.id !== tag.id && (item as IGroupTag).groupId !== tag.id,
            )
          : [
              ...this.selectedTags.filter(
                item => (item as IGroupTag).groupId !== tag.id,
              ),
              tag,
              ...groupItems,
            ];
      } else {
        const groupTag = this.tags.find(item => item.id === tag.groupId);
        const groupItems =
          this.tags.filter(
            item => (item as IGroupTag).groupId === tag.groupId,
          ) || [];
        const selectedItems =
          this.selectedTags.filter(
            item => (item as IGroupTag).groupId === tag.groupId,
          ) || [];
        this.selectedTags = isSelected
          ? this.selectedTags.filter(
              item =>
                item.id !== tag.id && (tag as IGroupTag).groupId !== item.id,
            )
          : groupItems.length === selectedItems.length + 1
          ? [...this.selectedTags, tag, groupTag]
          : [...this.selectedTags, tag];
      }
      return;
    }
    this.selectedTags = isSelected
      ? this.selectedTags.filter(it => it.id !== tag.id)
      : [...this.selectedTags, tag];
  };

  @action
  onTagRadioClick = (item: string) => {
    const tag = { id: item };
    this.selectedTags = this.tagsIds.includes(tag.id)
      ? []
      : this.tags.filter(i => i.id === item);
  };

  @action
  onCreateTag = async () => {
    this.loading = true;

    const data = await this.editModeActions.onCreateTag(this.searchValue);

    if (data && data.tag) {
      const { tag } = data;
      this.tags = [...this.tags, tag];
      this.onSearch('');
    }

    this.loading = false;
  };

  @action
  onDeleteTag = (tagId: string) => async () => {
    this.loading = true;

    const data = await this.editModeActions.onDeleteTag(tagId);

    if (data && data.id) {
      this.selectedTags = this.selectedTags.filter(tag => tag.id !== tagId);
      this.tags = this.tags.filter(tag => tag.id !== tagId);
    }

    this.loading = false;
  };
}

export default TagsSelectModalStore;
