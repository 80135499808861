import http from 'http/http';
import { api } from 'http/helpers';
import URLS from 'http/urls';

import { CreateUpdatePLUItemDto, PLUItem } from './types';

export const pluItemsApi = {
  fetchPLUItem(pluId: string) {
    return http.get<PLUItem>(api(URLS.pluItems.item), {
      replacePathParams: { pluId },
    });
  },

  fetchPLUItemsByPeriod(from: string, to: string) {
    return http.get<{ [cell: string]: PLUItem[] }>(api(URLS.pluItems.period), {
      replacePathParams: { from, to },
    });
  },

  createPLUItem(data: CreateUpdatePLUItemDto) {
    return http.post(api(URLS.pluItems.items), data);
  },

  updatePLUItem(pluId: string, data: CreateUpdatePLUItemDto) {
    return http.put(api(URLS.pluItems.item), data, {
      replacePathParams: { pluId },
    });
  },

  deletePLUItem(pluId: string) {
    return http.delete(api(URLS.pluItems.item), {
      replacePathParams: { pluId },
    });
  },
};
