import React, { createContext, ReactNode, RefObject } from 'react';
import { observer } from 'mobx-react';

export interface MultiSelectOption {
  label: string;
  id: string;
}

export interface MultiSelectAdditionalOption {
  label: string;
  id: string;
  onClick: () => void;
}

export interface MultiSelectContext {
  options: MultiSelectOption[];
  selectedOptionIds: string[];
  toggleOption: (optionId: string) => void;
  additionalOptions?: MultiSelectAdditionalOption[];
}

export interface MultiSelectContextUI {
  isOpened: boolean;
  disabled?: boolean;
  toggleOptions: () => void;
  refElement: RefObject<HTMLDivElement>;
}

interface IProps {
  context: MultiSelectContext;
  disabled?: boolean;
  children: ReactNode;
}

export const MultiSelectContext = createContext<
  (MultiSelectContext & MultiSelectContextUI) | null
>(null);

export const MultiSelectContainer = observer(
  ({ context, disabled, children }: IProps) => {
    const [isOpened, setIsOpened] = React.useState(false);
    const refElement = React.useRef<HTMLDivElement | null>(null);
    const toggleOptions = () => setIsOpened(!isOpened);

    return (
      <MultiSelectContext.Provider
        value={{ ...context, disabled, isOpened, toggleOptions, refElement }}
      >
        {children}
      </MultiSelectContext.Provider>
    );
  },
);
