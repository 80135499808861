import React, { Component } from 'react';

import RootStore from 'stores/RootStore';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';

import style from './PLUPlanner.module.scss';

interface Props {
  title: string;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
}

class PLUPlannerItemOverlay extends Component<Props> {
  render() {
    const { title, onEditClick, onDeleteClick } = this.props;

    return (
      <div className={style.tooltip}>
        <div className={style.tooltip__title} title={title}>
          {title}
        </div>

        <div className={style.tooltip__actions}>
          <Button
            title={RootStore.localization.formatMessage('button.edit')}
            styleType="text"
            className={style.btn}
            onClick={onEditClick}
          >
            <Icon type="edit" />
          </Button>
          <Button
            title={RootStore.localization.formatMessage('button.delete')}
            styleType="text"
            className={style.btn}
            onClick={onDeleteClick}
          >
            <Icon type="trash" />
          </Button>
        </div>
      </div>
    );
  }
}

export default PLUPlannerItemOverlay;
