import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import Modal from 'rc-dialog';
import classNames from 'classnames';

import { ModalProps } from 'stores/ModalStore';

import Button from 'components/Button/Button';

import style from './PLUCellModal.module.scss';

const ROWS_DEFAULT = 5;

export interface PLUCellModalProps extends ModalProps {
  value?: number[];
  multiple?: boolean;
  readMode?: boolean;
  onChange?: (cells: number[]) => void;
}

@observer
class PLUCellModal extends Component<PLUCellModalProps> {
  @observable private _selectedCells = this.props.value ?? [];
  @observable private _rows = ROWS_DEFAULT;
  private _cellsPerRow = 4;

  constructor(props: PLUCellModalProps) {
    super(props);

    this._rows = this._calculateRows();
  }

  render() {
    const { readMode, onClose, ...props } = this.props;

    return (
      <Modal
        {...props}
        className={style.modal}
        destroyOnClose
        animation="zoom"
        maskAnimation="fade"
        onClose={onClose}
        visible
      >
        <h2 className={style.modal__header}>
          <FormattedMessage id="title.pluCellsSelect" />
        </h2>

        <div className={style.cell__wrapper}>{this._renderCells()}</div>

        <div className={style.controls}>
          <Button className={style.btn} styleType="ghost" onClick={onClose}>
            <FormattedMessage id="button.cancel" />
          </Button>

          {!readMode && (
            <Button
              className={style.btn}
              onClick={this._handleSave}
              disabled={!this._selectedCells.length}
            >
              <FormattedMessage id="button.saveChanges" />
            </Button>
          )}
        </div>
      </Modal>
    );
  }

  @action
  private _handleSelect = (cell: number) => {
    const { readMode, multiple } = this.props;
    if (readMode) return;

    const isSelected = this._selectedCells.includes(cell);

    if (isSelected) {
      this._selectedCells = this._selectedCells.filter(
        cellItem => cellItem !== cell,
      );
      return;
    }

    if (multiple) {
      this._selectedCells = [...this._selectedCells, cell];
      return;
    }

    this._selectedCells = [cell];
  };

  private _handleSave = () => {
    const { onChange, onClose } = this.props;

    onChange?.(this._selectedCells);
    onClose();
  };

  @action
  private _handleAddRow = () => {
    this._rows += 1;
  };

  private _calculateRows = () => {
    const maxCell = Math.max(...(this.props.value ?? []));

    if (!maxCell) return ROWS_DEFAULT;
    const rows = Math.ceil(maxCell / this._cellsPerRow);

    if (rows < ROWS_DEFAULT) return ROWS_DEFAULT;
    return rows;
  };

  private _renderRow = (row: number) => {
    const cells: number[] = [];

    for (let i = 1; i <= this._cellsPerRow; i++) {
      const cellNumber = row * this._cellsPerRow + i;
      cells.push(cellNumber);
    }

    return (
      <div className={style.cell__row} key={row}>
        {cells.map(cell => {
          const isSelected = this._selectedCells.includes(cell);

          return (
            <div
              key={cell}
              className={classNames(
                style.cell,
                isSelected && style.selected,
                this.props.readMode && style.read__mode,
              )}
              onClick={() => this._handleSelect(cell)}
            >
              <span className={style.cell__number}>{cell}</span>
              <span className={style.cell__icon}>+</span>
            </div>
          );
        })}
      </div>
    );
  };

  private _renderCells = () => {
    const rows: JSX.Element[] = [];

    for (let i = 0; i < this._rows; i++) {
      rows.push(this._renderRow(i));
    }

    return (
      <>
        {rows}

        {!this.props.readMode && (
          <Button
            className={style.btn__add}
            styleType="text"
            onClick={this._handleAddRow}
          >
            <FormattedMessage id="button.addPLUCellRow" />
          </Button>
        )}
      </>
    );
  };
}

export default PLUCellModal;
