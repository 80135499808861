import React, { Fragment, useContext } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { MultiSelectContext } from 'components/MultiSelect/MultiSelectContainer';
import Icon from 'components/Icon/Icon';

import style from './MultiSelect.module.scss';

interface IProps {
  className?: string;
}

export const MultiSelectField = observer(({ className }: IProps) => {
  const context = useContext(MultiSelectContext);

  if (!context) {
    return null;
  }

  const selectedOptions = context.options.filter(option =>
    context.selectedOptionIds.includes(option.id),
  );

  return (
    <div
      className={classNames(
        className,
        style.field,
        context.disabled && style.disabled,
      )}
      onClick={context.toggleOptions}
      ref={context.refElement}
      aria-expanded={context.isOpened}
      tabIndex={0}
    >
      {selectedOptions.length > 0 ? (
        <span className={style.value}>
          {selectedOptions.map((option, index) => (
            <Fragment key={option.id}>
              <FormattedMessage
                id={option.label}
                defaultMessage={option.label}
              />
              {index < selectedOptions.length - 1 && ', '}
            </Fragment>
          ))}
        </span>
      ) : (
        <span className={style.value}>
          <FormattedMessage id="menu.copy.everyDay" />
        </span>
      )}
      <Icon
        type={context.isOpened ? 'arrowTop' : 'arrowBottom'}
        className={style.icon}
      />
    </div>
  );
});
