import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import PLUItemForm from 'stores/Forms/Settings/PLUItemForm';
import { PLUItemMenuItemType } from 'stores/Forms/Settings/types';
import RootStore from 'stores/RootStore';

import FormStepperTitle from 'components/FormStepper/FormStepperTitle';
import { FormControl } from 'components/Form/FormControl/FormControl';
import SelectField from 'components/Form/Fields/Select/SelectField';
import TimeRange from 'components/Form/Fields/TimeRange/TimeRange';

import style from './PLUItemSteps.module.scss';

interface Props {
  form: PLUItemForm;
}

@observer
class PLUItemStepHours extends Component<Props> {
  render() {
    const { form } = this.props;

    return (
      <div className={style.step_item}>
        <FormStepperTitle
          label="title.settings.plu.steps.hours"
          tooltip="title.settings.plu.steps.hours.description"
        />

        <div className={style.form_group}>
          <div className={style.form_group_description}>
            <FormattedMessage id="title.settings.plu.steps.hours.type" />
          </div>

          <SelectField
            value={form.menuItemType}
            options={this._menuItemTypeOptions}
            className={style.form_control}
            onChange={this._handleMenuItemTypeChange}
          />
        </div>

        <div className={style.form_group}>
          <div className={style.form_group_description}>
            <FormattedMessage id="title.settings.plu.steps.hours.availableTime" />
          </div>

          <FormControl
            form={form}
            name="availableTime"
            className={style.form_control}
            render={props => (
              <TimeRange
                {...props}
                disabled={form.menuItemType !== PLUItemMenuItemType.Temporary}
                expanded
                error={this._timeRangeError}
                format="HH:mm"
              />
            )}
          />
        </div>
      </div>
    );
  }

  private _handleMenuItemTypeChange = (value: PLUItemMenuItemType | null) => {
    this.props.form.handleMenuItemTypeChange(
      value ?? PLUItemMenuItemType.Standard,
    );
  };

  private get _menuItemTypeOptions() {
    return Object.values(PLUItemMenuItemType).map(type => {
      const label = RootStore.localization.formatMessage(
        `title.settings.plu.steps.hours.type.${type}`,
      );

      return {
        label,
        value: type,
      };
    });
  }

  private get _timeRangeError() {
    const { form } = this.props;
    if (!form.validated) return null;

    return form.errorFor('availableTime')[0];
  }
}

export default PLUItemStepHours;
