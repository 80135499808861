import classNames from 'classnames';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import ModalStore from 'stores/ModalStore';
import { ValidationResult } from 'stores/BaseForm/types';

import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import PLUCellModal, {
  PLUCellModalProps,
} from 'components/Modals/PLUCellModal/PLUCellModal';

import style from './PLUCellSelect.module.scss';
import FormControlError from 'components/Form/FormControl/FormControlError';

interface Props extends Omit<PLUCellModalProps, 'onClose'> {
  description?: string;
  titleNotSelected?: string;
  error?: ValidationResult | null;
  className?: {
    container?: string;
    field?: string;
    label?: string;
    selectedCell?: string;
  };
}

class PLUCellSelect extends Component<Props> {
  render() {
    const {
      value,
      description,
      titleNotSelected,
      className,
      error,
    } = this.props;

    return (
      <div className={classNames(style.container, className?.container)}>
        <div className={classNames(style.field, className?.field)}>
          <div className={classNames(style.label, className?.label)}>
            <FormattedMessage id="title.pluCells" />
          </div>
          <Button
            styleType="text"
            className={style.btn}
            onClick={this._handleSelectClick}
          >
            <FormattedMessage id="button.select" />
          </Button>
          <Button
            styleType="text"
            className={style.btn}
            onClick={this._handleResetClick}
            disabled={!value?.length}
          >
            <FormattedMessage id="button.reset" />
          </Button>
        </div>

        {description && (
          <div className={style.description}>
            <FormattedMessage id={description} />
          </div>
        )}

        <div className={style.selected}>
          {!!value?.length ? (
            <ul className={style.selected__list}>
              {value.map(cell => (
                <li key={cell} className={style.selected__item}>
                  <span className={style.cell__title}>{cell}</span>

                  <Button
                    styleType="text"
                    onClick={() => this._handleRemoveCellClick(cell)}
                    className={style.cell__btn}
                  >
                    <Icon type="close" />
                  </Button>
                </li>
              ))}
            </ul>
          ) : (
            <div className={style.selected__message}>
              <FormattedMessage id={titleNotSelected || 'title.not.selected'} />
            </div>
          )}
        </div>

        {!!error && (
          <div className={style.error}>
            <FormControlError error={error} />
          </div>
        )}
      </div>
    );
  }

  private _handleSelectClick = async () => {
    await ModalStore.showModal(PLUCellModal, {
      // readMode: true,
      ...this.props,
    });
  };

  private _handleRemoveCellClick = (cell: number) => {
    const { value, onChange } = this.props;

    onChange?.(value?.filter(cellItem => cellItem !== cell) ?? []);
  };

  private _handleResetClick = () => {
    this.props.onChange?.([]);
  };
}

export default PLUCellSelect;
