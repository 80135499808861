import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import style from './Tabs.module.scss';

import Button from '../Button/Button';

export interface AppTabControlled {
  title: string;
  index: number;
}

interface Props {
  tabs: AppTabControlled[];
  activeTab: number;
  tabListClass?: string;
  tabClass?: string;
  onTabClick?: (index: number) => void;
}

class TabsControlled extends Component<Props> {
  private _handleTabClick(index: number) {
    const { activeTab, onTabClick } = this.props;

    if (index !== activeTab) {
      onTabClick?.(index);
    }
  }

  render() {
    const { tabs, activeTab, tabListClass, tabClass } = this.props;

    return (
      <ul className={classNames(style.tab__list, tabListClass)}>
        {tabs.map(tab => (
          <li key={tab.index}>
            <Button
              styleType="text"
              className={classNames(
                style.btn__tab,
                tabClass,
                activeTab === tab.index && [style.tab__active],
              )}
              onClick={() => this._handleTabClick(tab.index)}
            >
              {typeof tab.title === 'string' ? (
                <FormattedMessage id={tab.title} defaultMessage={tab.title} />
              ) : (
                tab.title
              )}
            </Button>
          </li>
        ))}
      </ul>
    );
  }
}

export default TabsControlled;
