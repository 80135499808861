import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import PLUPlannerStore from 'stores/Settings/PLUPlannerStore';

import Spinner from 'components/Spinner/Spinner';

import PLUPlannerCalendar from './PLUPlannerCalendar';
import PLUPlannerGrid from './PLUPlannerGrid';
import style from './PLUPlanner.module.scss';
import Icon from 'components/Icon/Icon';

interface Props {
  store: PLUPlannerStore;
}

@observer
class PLUPlanner extends Component<Props> {
  render() {
    const { weekNumber, isWeekDisabled, loading } = this.props.store;

    // const printTitle = `${intl.formatMessage({
    //   id: 'title.planner.print.title',
    // })} (KW ${menuPlanner.weekNumber}, ${menuPlanner.weekStartDate.toFormat(
    //   DATE_FORMAT,
    // )} - ${menuPlanner.weekEndDate.toFormat(DATE_FORMAT)})`;

    return (
      <div className={style.content}>
        {/* <CopyMenu store={this.store} /> */}
        <header className={style.header}>
          <div className={style.planner__title}>
            <div className={style.planner__week}>
              <FormattedMessage id="WEEK" />
              &nbsp;
              {weekNumber}
              {/* {isCopyMode && copyMenu?.copyMode === CopyMode.WEEK && (
                <div
                  className={classNames(
                    style.selectionMask,
                    style.selectionMaskWeek,
                    {
                      [style.active]:
                        copyMenu.periodFrom[0].weekNumber ===
                        menuPlanner.weekNumber,
                    },
                  )}
                  onClick={copyMenu.setPeriodFrom(menuPlanner.date)}
                />
              )} */}
            </div>

            {/* <div className={style.planner__print}>
              {menuPlanner?.catering?.logo && (
                <img
                  className={style.catering__logo}
                  src={menuPlanner.catering.logo}
                  alt="catering logo"
                />
              )}
              <div className={style.print__header}>
                <div className={style.print__title}>{printTitle}</div>
                {menuPlanner?.catering?.name && (
                  <div>{menuPlanner.catering.name}</div>
                )}
              </div>
            </div> */}
          </div>

          <PLUPlannerCalendar store={this.props.store} />

          {isWeekDisabled && (
            <div className={style.warning}>
              <Icon type="info" className={style.icon__info} />
              <FormattedMessage id="warning.weekBlocked" />
            </div>
          )}

          {/* {!this.store.isCopyMode && (
            <Button
              styleType="text"
              onClick={() => {
                document.title = printTitle;
                window.print();
                document.title = 'sionum';
              }}
              className={style.btn__print}
            >
              <Icon type="print" />
            </Button>
          )} */}
        </header>

        <div className={style.planner__footer__container}>
          <PLUPlannerGrid store={this.props.store} />
        </div>

        {loading && <Spinner contain />}
      </div>
    );
  }
}

export default PLUPlanner;
