import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ValidationResult } from 'stores/BaseForm/types';

import style from './FormControl.module.scss';

interface FormControlErrorsProps {
  error: ValidationResult;
}

const FormControlError = ({ error }: FormControlErrorsProps) => {
  return (
    <span className={style.error}>
      <FormattedMessage
        id={error.message}
        defaultMessage={error.message}
        key={error.message}
        values={{
          ...error.data,
          name: (
            <FormattedMessage
              id={error.data.name}
              defaultMessage={error.data.name}
            />
          ),
          compareName: (
            <FormattedMessage
              id={error.data.compareName}
              defaultMessage={error.data.compareName}
            />
          ),
        }}
      />
    </span>
  );
};

export default FormControlError;
