import React, { Component, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import style from './SettingItem.module.scss';

interface Props {
  title: string;
  children?: ReactNode;
}

class SettingItem extends Component<Props> {
  render() {
    const { title, children } = this.props;

    return (
      <div className={style.wrapper}>
        <h2 className={style.title}>
          <FormattedMessage id={title} />
        </h2>
        {children}
      </div>
    );
  }
}

export default SettingItem;
