import { action, observable } from 'mobx';
import { DateTime } from 'luxon';

import {
  BaseFormModel,
  hasMaxLength,
  isRequired,
  displayName,
} from 'stores/BaseForm';
import { getLocalDateTime } from 'helpers/datetime';
import { PLUItemComponent } from 'http/Api/PLUItems/types';
import { isTimeRange } from 'stores/BaseForm/validators/isTimeRange';

import { TimeRangeType } from 'components/Form/Fields/TimeRange/TimeRange';
import { PLUItemMenuItemType } from './types';

const DEFAULT_TIME_RANGE: TimeRangeType = ['00:00', '23:59'];

class PLUItemForm extends BaseFormModel {
  @observable
  @isRequired()
  component = PLUItemComponent.Spt;

  @observable
  period: [DateTime, DateTime] = [getLocalDateTime(), getLocalDateTime()];

  @observable
  weekDays: string[] = [];

  @observable
  @hasMaxLength(100)
  alias = '';

  @observable
  @displayName('title.settings.plu.steps.assignment.menuItem')
  @isRequired()
  menuItemId = '';

  @observable
  @displayName('title.pluCells')
  @isRequired()
  cell: number | null = null;

  @observable
  @isRequired()
  menuItemType = PLUItemMenuItemType.Standard;

  @observable
  @isTimeRange()
  availableTime: TimeRangeType = DEFAULT_TIME_RANGE;

  @action
  public handlePeriodChange = ([from, to]: DateTime[]) => {
    this.period = [from, to];
    this.weekDays = [];
  };

  @action
  public handleCellSelect = (cells: number[]) => {
    this.cell = cells.length ? cells[0] : null;
  };

  @action
  public handleMenuItemSelect = (menuItemId?: string) => {
    if (!menuItemId) return;

    this.menuItemId = menuItemId;
  };

  @action
  public handleMenuItemRemove = () => {
    this.menuItemId = '';
  };

  @action
  public handleWeekDaysChange = (weekDays: string[]) => {
    this.weekDays = weekDays;
  };

  @action
  public handleMenuItemTypeChange = (menuItemType: PLUItemMenuItemType) => {
    if (menuItemType === PLUItemMenuItemType.Standard) {
      this.availableTime = DEFAULT_TIME_RANGE;
    }

    this.menuItemType = menuItemType;
  };
}

export default PLUItemForm;
