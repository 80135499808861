import React, { Component } from 'react';

import SettingItem from './SettingItem/SettingItem';
import PLUSetting from './PLUSetting/PLUSetting';
import style from './SettingsPage.module.scss';

class SettingsPage extends Component {
  render() {
    return (
      <main className={style.main}>
        <SettingItem title="title.settings.plu">
          <PLUSetting />
        </SettingItem>
      </main>
    );
  }
}

export default SettingsPage;
