export interface PLUItem {
  pluId: string;
  alias: string;
  component: PLUItemComponent;
  dates: string[];
  from: string;
  to: string;
  cell: number;
  menuItemId: string;
}

export enum PLUItemComponent {
  Spt = 'SPT',
  Consumer = 'CONSUMER',
  All = 'ALL',
}

export interface CreateUpdatePLUItemDto {
  cell: number;
  alias: string;
  component: PLUItemComponent;
  dates: string[];
  from: string;
  to: string;
  menuItemUUID: string;
}
