import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import PLUItemForm from 'stores/Forms/Settings/PLUItemForm';
import { isBeforeDate, isSameDate } from 'helpers/datetime';

import FormStepperTitle from 'components/FormStepper/FormStepperTitle';
import Datepicker from 'components/Form/Fields/Datepicker/Datepicker';
import DaysMultiSelect from 'components/MultiSelect/DaysMultiSelect/DaysMultiSelect';

import style from './PLUItemSteps.module.scss';
import { computed } from 'mobx';

interface Props {
  form: PLUItemForm;
}

@observer
class PLUItemStepDate extends Component<Props> {
  render() {
    const { form } = this.props;

    return (
      <div className={style.step_item}>
        <FormStepperTitle
          label="title.settings.plu.steps.date"
          tooltip="title.settings.plu.steps.date.description"
        />

        <div className={style.form_group}>
          <div className={style.control_wrapper}>
            <div className={style.control_label}>
              <FormattedMessage id="title.period" />
            </div>
            <Datepicker
              value={form.period}
              selectRange
              showWeekNumbers
              showPeriods={false}
              icon
              styles={{
                calendar: style.datepicker,
                button: style.datepickerButton,
                icon: style.datepickerIcon,
              }}
              onChange={form.handlePeriodChange}
              isDateDisabled={this._isDateDisabled}
            />
          </div>
        </div>

        <div className={style.form_group}>
          <div className={style.control_wrapper}>
            <div className={style.control_label}>
              <FormattedMessage id="title.settings.plu.steps.date.weekdays" />
            </div>
            <DaysMultiSelect
              value={form.weekDays}
              disabled={this._isWeekDaysSelectDisabled}
              fieldClassName={style.daysSelect}
              onChange={form.handleWeekDaysChange}
            />
          </div>
        </div>
      </div>
    );
  }

  private _isDateDisabled = (date: Date) => {
    return isBeforeDate(date, new Date());
  };

  @computed
  private get _isWeekDaysSelectDisabled() {
    const { period } = this.props.form;

    const dateFrom = period[0].toJSDate();
    const dateTo = period[1].toJSDate();

    return isSameDate(dateFrom, dateTo);
  }
}

export default PLUItemStepDate;
