import React, { Component, ReactNode } from 'react';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker/dist/entry.nostyle';
import classNames from 'classnames';

import { ValidationResult } from 'stores/BaseForm/types';
import RootStore from 'stores/RootStore';
import { SHORT_TIME_FORMAT } from 'helpers/datetime';

import FormControlError from 'components/Form/FormControl/FormControlError';
import { checkAndAutofillPartsOfTime } from './helpers';
import style from './TimeRange.module.scss';

export type TimeRangeType = [string | null, string | null];

interface Props {
  onChange: (value: TimeRangeType) => void;
  value: TimeRangeType;
  format?: string;
  locale?: string;
  disableClock?: boolean;
  clearIcon?: ReactNode | null;
  disabled?: boolean;
  invalid?: boolean;
  expanded?: boolean;
  error?: ValidationResult | null;
}

class TimeRange extends Component<Props> {
  timerangeRef = React.createRef<HTMLDivElement>();

  componentDidMount() {
    if (this.timerangeRef.current) {
      const timeInputs = this.timerangeRef.current.querySelectorAll(
        'input[type="number"]',
      );
      (Array.from(timeInputs) as HTMLInputElement[]).forEach(input => {
        /* reset max value for manual validation */
        input.max = '100';
      });
    }
  }
  onChange = value => {
    if (this.timerangeRef.current) {
      let time;
      const timeInputs = this.timerangeRef.current.querySelectorAll(
        'input[type="number"]',
      );
      time = checkAndAutofillPartsOfTime(
        Array.from(timeInputs) as HTMLInputElement[],
        value,
      );
      if (Array.isArray(time)) {
        this.props.onChange(time as TimeRangeType);
      }
    }
  };

  render() {
    const {
      onChange,
      value,
      invalid,
      format = SHORT_TIME_FORMAT,
      locale,
      disableClock = true,
      clearIcon = null,
      disabled,
      expanded,
      error,
      ...props
    } = this.props;

    return (
      <>
        <div className={style.timerange__wrapper} ref={this.timerangeRef}>
          <TimeRangePicker
            {...props}
            onChange={this.onChange}
            value={value}
            format={format}
            locale={locale || RootStore.localization.locale}
            disableClock={disableClock}
            clearIcon={clearIcon}
            rangeDivider={expanded ? '-' : ''}
            maxTime={null}
            disabled={disabled}
            className={classNames(
              invalid && 'react-timerange-picker--invalid',
              expanded && 'timerange__expended',
            )}
          />
        </div>

        {!!error && (
          <div className={style.error}>
            <FormControlError error={error} />
          </div>
        )}
      </>
    );
  }
}

export default TimeRange;
