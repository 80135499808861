import classNames from 'classnames';
import { action, computed } from 'mobx';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button/Button';
import TagsSelectModal, {
  IGroupTag,
  ITag,
  TagsSelectModalProps,
} from 'components/Modals/TagsSelectModal/TagsSelectModal';
import Icon from 'components/Icon/Icon';
import FormControlError from 'components/Form/FormControl/FormControlError';

import ModalStore from 'stores/ModalStore';
import { ValidationResult } from 'stores/BaseForm/types';

import style from './TagsSelect.module.scss';

interface Props<T extends ITag>
  extends Omit<TagsSelectModalProps<T>, 'onClose'> {
  selectedTags: T[];
  onSubmit: (tags: T[]) => void;
  onRemoveTag: (id?: string) => void;
  onResetAllTags?: (tags: T[]) => void;
  titleNotSelected?: string;
  description?: string;
  error?: ValidationResult | null;
  className?: {
    container?: string;
    field?: string;
    label?: string;
    selectedTag?: string;
  };
}

@observer
class TagsSelect<T extends ITag> extends Component<Props<T>> {
  @action.bound
  async onSelectTags() {
    const { payload } = await ModalStore.showModal(TagsSelectModal, {
      ...this.props,
    });
    this.props.onSubmit(payload);
  }

  onRemoveTag = tag => () => {
    this.props.onRemoveTag(tag.id);
  };

  onResetAllTags = () => {
    if (this.props.onResetAllTags) {
      return this.props.onResetAllTags(this.props.selectedTags);
    }
    this.props.onRemoveTag();
  };

  @computed
  get tags() {
    const { selectedTags, withGroups } = this.props;
    if (withGroups) {
      const groups = selectedTags.filter(item => (item as IGroupTag).isGroup);
      return selectedTags.filter(
        item => !groups.some(group => group.id === (item as IGroupTag).groupId),
      );
    }
    return selectedTags;
  }

  render() {
    const {
      selectedTags,
      title,
      className,
      titleNotSelected,
      description,
      error,
    } = this.props;

    return (
      <div className={classNames(style.container, className?.container)}>
        <div className={classNames(style.field, className?.field)}>
          <div className={classNames(style.label, className?.label)}>
            <FormattedMessage id={title} />
          </div>
          <Button
            styleType="text"
            className={style.btn}
            onClick={this.onSelectTags}
          >
            <FormattedMessage id="button.select" />
          </Button>
          <Button
            styleType="text"
            className={style.btn}
            onClick={this.onResetAllTags}
            disabled={!selectedTags?.length}
          >
            <FormattedMessage id="button.reset" />
          </Button>
        </div>

        {description && (
          <div>
            <div className={classNames(style.description)}>
              <FormattedMessage id={description} />
            </div>
          </div>
        )}

        <div className={style.selected}>
          {Boolean(this.tags.length) ? (
            <ul className={style.selected__list}>
              {this.tags.map(it => (
                <li
                  key={it.id}
                  className={classNames(style.selected__item, {
                    [`${style.subsidizedNotAllItemsTag}`]:
                      it?.noted || (it as IGroupTag)?.isGroup,
                  })}
                >
                  <span className={style.tag__title}>{it.title}</span>

                  <Button
                    styleType="text"
                    onClick={this.onRemoveTag(it)}
                    className={style.tag__btn}
                  >
                    <Icon type="close" />
                  </Button>
                </li>
              ))}
            </ul>
          ) : (
            <div className={style.selected__message}>
              <FormattedMessage id={titleNotSelected || 'title.not.selected'} />
            </div>
          )}
        </div>

        {!!error && (
          <div className={style.error}>
            <FormControlError error={error} />
          </div>
        )}
      </div>
    );
  }
}

export default TagsSelect;
