import React, { Component } from 'react';

import PLUItemForm from 'stores/Forms/Settings/PLUItemForm';
import { PLUItemComponent } from 'http/Api/PLUItems/types';

import FormStepperTitle from 'components/FormStepper/FormStepperTitle';
import { FormControl } from 'components/Form/FormControl/FormControl';
import RadioField from 'components/Form/Fields/Radio/RadioField';

import style from './PLUItemSteps.module.scss';

interface Props {
  form: PLUItemForm;
}

class PLUItemStepSoftware extends Component<Props> {
  render() {
    return (
      <div className={style.step_item}>
        <FormStepperTitle
          label="title.settings.plu.steps.software"
          tooltip="title.settings.plu.steps.software.description"
        />

        <ul>
          {Object.values(PLUItemComponent).map(type => (
            <li className={style.form_row} key={type}>
              <FormControl
                name="component"
                form={this.props.form}
                render={fieldProps => (
                  <RadioField
                    {...fieldProps}
                    title={`title.settings.plu.steps.software.${type}`}
                    name={type}
                    value={type}
                    checked={this.props.form.component === type}
                    radioButtonStyleType={'circle'}
                    className={style.form_label}
                  />
                )}
              />
              {/* <div
                className={classNames(
                  style.form__description,
                  style.radio__description,
                )}
              >
                <FormattedMessage id={`${type}.DESCRIPTION`} />
              </div> */}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default PLUItemStepSoftware;
