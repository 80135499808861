import React, { PureComponent } from 'react';
import classNames from 'classnames';

import style from './PLUPlanner.module.scss';

class PLUPlannerTimeline extends PureComponent {
  render() {
    return (
      <div className={style.line__time}>
        <span className={style.time} style={{ top: this._getTopPosition(0) }}>
          00:00
        </span>

        {this._renderTimestamps(1, 5)}

        <span className={style.time} style={{ top: this._getTopPosition(6) }}>
          06:00
        </span>

        {this._renderTimestamps(7, 11)}

        <span className={style.time} style={{ top: this._getTopPosition(12) }}>
          12:00
        </span>

        {this._renderTimestamps(13, 17)}

        <span className={style.time} style={{ top: this._getTopPosition(18) }}>
          18:00
        </span>

        {this._renderTimestamps(19, 23)}

        <span className={style.time} style={{ top: this._getTopPosition(24) }}>
          23:59
        </span>
      </div>
    );
  }

  private _getTopPosition = (timestamp: number) => {
    return ((timestamp / 24) * 100).toFixed(1) + '%';
  };

  private _renderTimestamps = (from: number, to: number) => {
    const timestamps: JSX.Element[] = [];

    for (let i = from; i <= to; i++) {
      const top = this._getTopPosition(i);

      timestamps.push(
        <span
          key={i}
          className={classNames(style.time, style.timestampOnly)}
          style={{ top }}
        />,
      );
    }

    return timestamps;
  };
}

export default PLUPlannerTimeline;
