import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { getDateTimeWithLocale } from 'helpers/datetime';
import PLUPlannerStore from 'stores/Settings/PLUPlannerStore';
import ModalStore from 'stores/ModalStore';

import Icon from 'components/Icon/Icon';
import PLUCellModal from 'components/Modals/PLUCellModal/PLUCellModal';

import PLUPlannerRow from './PLUPlannerRow';
import style from './PLUPlanner.module.scss';

interface Props {
  store: PLUPlannerStore;
}

@observer
class PLUPlannerGrid extends Component<Props> {
  public plannerRef = React.createRef<HTMLDivElement>();

  render() {
    const { store } = this.props;

    return (
      <>
        <div className={style.planner} ref={this.plannerRef}>
          <div className={style.caption}>
            <div
              className={classNames(
                style.th,
                style.th__primary,
                style.th__cell,
              )}
            >
              <div className={style.caption__wrapper}>
                <FormattedMessage id="title.settings.plu.grid.cell" />

                <Icon
                  className={style.cell__icon}
                  type={'helpCircle'}
                  onClick={this._handleCellHelpIconClick}
                />
              </div>
            </div>

            <div
              className={classNames(
                style.th,
                style.th__primary,
                style.th__timeline,
              )}
            >
              <div className={style.caption__wrapper}>
                <FormattedMessage id="title.settings.plu.grid.time" />
              </div>
            </div>

            {store.periodDates.map(date => {
              const dateTime = getDateTimeWithLocale(date);

              return (
                <div key={date} className={classNames(style.th, style.weekday)}>
                  <span>{dateTime.day}</span>
                  <span>{dateTime.weekdayShort}</span>

                  {/* {menuPage?.isCopyMode &&
                    menuPage?.copyMenu?.copyMode === CopyMode.DAY && (
                      <div
                        className={classNames(
                          style.selectionMask,
                          style.selectionMaskDay,
                          {
                            [style.active]:
                              menuPage.copyMenu.from.toISODate() === date,
                          },
                        )}
                        style={{
                          height: this.plannerHeight - 8 + 'px',
                        }}
                        onClick={menuPage.copyMenu.setDateFrom(dateTime)}
                      />
                    )} */}
                </div>
              );
            })}
            <div className={classNames(style.th, style.th__hidden)} />
          </div>

          {this._renderRows()}
        </div>

        {this._renderNoData()}
      </>
    );
  }

  private _renderRows = () => {
    const { store } = this.props;

    const rows: JSX.Element[] = [];

    for (const cell in store.pluItems) {
      if (!store.pluItems.hasOwnProperty(cell) || !store.pluItems[cell].length)
        continue;

      rows.push(<PLUPlannerRow key={cell} cell={cell} store={store} />);
    }

    return rows;
  };

  private _renderNoData = () => {
    const { store } = this.props;

    for (const cell in store.pluItems) {
      if (!store.pluItems.hasOwnProperty(cell)) continue;
      if (store.pluItems[cell].length) return;
    }

    return (
      <div className={style.noDataText}>
        <FormattedMessage id="table.noDataText" />
      </div>
    );
  };

  private _handleCellHelpIconClick = async () => {
    await ModalStore.showModal(PLUCellModal, { readMode: true });
  };
}

export default PLUPlannerGrid;
