import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';

import PLUItemForm from 'stores/Forms/Settings/PLUItemForm';
import RootStore from 'stores/RootStore';

import FormStepperTitle from 'components/FormStepper/FormStepperTitle';
import { FormControl } from 'components/Form/FormControl/FormControl';
import TextField from 'components/Form/Fields/Text/TextField';
import TagsSelect from 'components/TagsSelect/TagsSelect';
import { IGroupTag } from 'components/Modals/TagsSelectModal/TagsSelectModal';
import PLUCellSelect from 'components/PLUCellSelect/PLUCellSelect';
import TooltipComponent from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import style from './PLUItemSteps.module.scss';

interface Props {
  form: PLUItemForm;
  menuItems: IGroupTag[];
}

@observer
class PLUItemStepAssignment extends Component<Props> {
  render() {
    const { form, menuItems } = this.props;

    const selectedMenuItem = menuItems.find(
      item => item.id === form.menuItemId,
    );

    return (
      <div className={style.step_item}>
        <FormStepperTitle
          label="title.settings.plu.steps.assignment"
          tooltip="title.settings.plu.steps.assignment.description"
        />

        <div className={style.form_group}>
          <div className={style.form_label}>
            <FormattedMessage id="title.settings.plu.steps.assignment.alias" />

            <TooltipComponent
              className={style.alias__tooltip}
              content={
                <FormattedMessage id="title.settings.plu.steps.assignment.alias.tooltip" />
              }
            >
              <Icon className={style.alias__icon} type={'helpCircle'} />
            </TooltipComponent>
          </div>
          <div className={style.form_description}>
            <FormattedMessage id="title.settings.plu.steps.assignment.alias.description" />
          </div>
          <FormControl
            form={form}
            name="alias"
            className={style.form_control}
            render={props => (
              <TextField
                {...props}
                placeholder={RootStore.localization.formatMessage(
                  'title.settings.plu.steps.assignment.alias.placeholder',
                )}
              />
            )}
          />
        </div>

        <div className={style.form_group}>
          <TagsSelect
            title="title.settings.plu.steps.assignment.menuItem"
            tags={menuItems}
            selectedTags={selectedMenuItem ? [selectedMenuItem] : []}
            onSubmit={this._handleMenuItemSelect}
            onRemoveTag={this._handleMenuItemRemove}
            withGroups
            isShowSearchField
            isGroupTagUnselectable
            isShowTags={false}
            inputTypes="radio"
            error={this._tagsError}
          />
        </div>

        <div className={style.form_group}>
          <PLUCellSelect
            value={form.cell ? [form.cell] : []}
            error={this._cellError}
            onChange={form.handleCellSelect}
          />
        </div>
      </div>
    );
  }

  private _handleMenuItemSelect = (items: IGroupTag[]) => {
    this.props.form.handleMenuItemSelect(items[0]?.id);
  };

  private _handleMenuItemRemove = () => {
    this.props.form.handleMenuItemRemove();
  };

  private get _tagsError() {
    const { form } = this.props;
    if (!form.validated) return null;

    return form.errorFor('menuItemId')[0];
  }

  private get _cellError() {
    const { form } = this.props;
    if (!form.validated) return null;

    return form.errorFor('cell')[0];
  }
}

export default PLUItemStepAssignment;
